import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PageWrapper from "../components/PageWrapper"
import Button from "../components/Button"
import LostIcon from "../svg/LostIcon"

const NotFoundPage = () => {

  const errorScroll = [
    {
      title: `404 Error`
    },
    {
      title: `Page Not Found`
    },
    {
      title: `404 Error`
    },
    {
      title: `Page Not Found`
    }
  ]

  return (
    <Layout>
      <Seo title="404 Error" />
      <PageWrapper scrollData={errorScroll}>
        <div className={`text-center min-h-[50vh] py-12`}>
        <LostIcon className={`mx-auto w-64 mb-10 -mt-8`} />
        <h1 className={`uppercase text-6xl italic`}>Page Not Found</h1>
        <p>Looks like you're lost...</p>
        <Button type={`internal`} to={`/`}>Home</Button>
        </div>

        {/* beginning of test */}
        {/* <FlushWrapper className={`bg-indigo-400`}>
              <p>Yooo</p>
        </FlushWrapper> */}
         {/* endf of test */}
      </PageWrapper>
      
      
    </Layout>
  )
}

export default NotFoundPage
